<template>
  <div
    class="
      min-h-screen
      bg-secondary-light
      flex flex-col
      items-center
      justify-center
    "
  >
    <div
      class="grid grid-cols-1 lg:grid-cols-4 lg:max-w-4xl xl:max-w-5xl mx-auto"
    >
      <div
        class="
          bg-secondary-light
          text-primary-dark
          md:bg-white
          text-xl
          flex
          items-center
          h-64
          xl:h-72
          lg:col-start-4 lg:col-end-5
          lg:row-start-1 lg:row-end-2
        "
      >
        <p
          class="
            leading-tight
            px-6
            xl:px-8
            text-2xl
            lg:text-xl
            text-center
            md:text-left
          "
        >
          {{ $t("wines.body") }}
        </p>
      </div>
      <div class="col-span-3">
        <swiper
          :breakpoints="breakpoints"
          @swiper="onSwiper"
          :autoplay="{
            delay: 3000,
          }"
          :loop="true"
        >
          <swiper-slide v-for="wine in wines" :key="wine.varietal">
            <div class="thumb">
              <div class="thumb-bottle">
                <img
                  :src="wine.thumb"
                  :alt="wine.varietal"
                  class="object-cover h-64 xl:h-72"
                />
              </div>
              <div class="thumb-footer">
                <div>
                  {{ wine.varietal }} <br /><span class="font-bold">{{
                    wine.detail
                  }}</span>
                </div>
                <div>
                  <a :href="wine.url" target="_blank"
                    ><ion-icon
                      name="document-text-outline"
                      class="text-xl text-green-700"
                    ></ion-icon
                  ></a>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
SwiperCore.use([Autoplay]);

import Malbec from "../assets/images/wines/malbec.jpg";
import MalbecReserva from "../assets/images/wines/malbec-reserva.jpg";
import MalbecGranReserva from "../assets/images/wines/malbec-granReserva.jpg";
import MalbecSyrah from "../assets/images/wines/malbec-syrah.jpg";
import CabernetSauvignonReserva from "../assets/images/wines/cabernetSauvignon-reserva.jpg";
import CabernetSyrah from "../assets/images/wines/cabernet-syrah.jpg";
import Syrah from "../assets/images/wines/syrah.jpg";
import VinoTinto from "../assets/images/wines/vino-tinto.jpg";
import SauvignonBlanc from "../assets/images/wines/sauvignon-blanc.jpg";
import Torrontes from "../assets/images/wines/torrontes.jpg";
import VinoBlanco from "../assets/images/wines/vino-blanco.jpg";
import MalbecRose from "../assets/images/wines/malbec-rose.jpg";

import MalbecPdf from "../assets/pdf/malbec.pdf";
import MalbecReservaPdf from "../assets/pdf/malbec-reserva.pdf";
import MalbecGranReservaPdf from "../assets/pdf/malbec-gran-reserva.pdf";
import MalbecSyrahPdf from "../assets/pdf/malbec-syrah.pdf";
import CabernetSauvignonReservaPdf from "../assets/pdf/cabernet-sauvignon.pdf";
import CabernetSyrahPdf from "../assets/pdf/cabernet-syrah.pdf";
import SyrahPdf from "../assets/pdf/syrah.pdf";
import SauvignonBlancPdf from "../assets/pdf/sauvignon-blanc.pdf";
import TorrontesPdf from "../assets/pdf/torrontes.pdf";
import VinoBlancoPdf from "../assets/pdf/vino-blanco.pdf";
import VinoRosadoPdf from "../assets/pdf/vino-rosado.pdf";
import VinoTintoPdf from "../assets/pdf/vino-tinto.pdf";

const wines = [
  {
    id: 1,
    varietal: "Malbec",
    detail: " ",
    thumb: Malbec,
    url: MalbecPdf,
  },
  {
    id: 2,
    varietal: "Malbec",
    detail: "Reserva",
    thumb: MalbecReserva,
    url: MalbecReservaPdf,
  },
  {
    id: 3,
    varietal: "Malbec",
    detail: "Gran Reserva",
    thumb: MalbecGranReserva,
    url: MalbecGranReservaPdf,
  },
  {
    id: 4,
    varietal: "Malbec",
    detail: "Syrah",
    thumb: MalbecSyrah,
    url: MalbecSyrahPdf,
  },
  {
    varietal: "Cabernet Sauvignon",
    detail: "Reserva",
    thumb: CabernetSauvignonReserva,
    url: CabernetSauvignonReservaPdf,
  },
  {
    varietal: "Cabernet",
    detail: "Syrah",
    thumb: CabernetSyrah,
    url: CabernetSyrahPdf,
  },
  {
    varietal: "Syrah",
    detail: " ",
    thumb: Syrah,
    url: SyrahPdf,
  },
  {
    varietal: "Sauvignon Blanc",
    detail: "",
    thumb: SauvignonBlanc,
    url: SauvignonBlancPdf,
  },
  {
    varietal: "Torrontes",
    detail: " ",
    thumb: Torrontes,
    url: TorrontesPdf,
  },
  {
    varietal: "Vino Blanco",
    detail: " ",
    thumb: VinoBlanco,
    url: VinoBlancoPdf,
  },
  {
    varietal: "Vino Rosado",
    detail: " ",
    thumb: MalbecRose,
    url: VinoRosadoPdf,
  },
  {
    varietal: "Vino Tinto",
    detail: " ",
    thumb: VinoTinto,
    url: VinoTintoPdf,
  },
];

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    };
  },
  setup() {
    return {
      wines,
    };
  },
};
</script>

<style>
.thumb-bottle {
  @apply bg-secondary-dark lg:h-64 xl:h-72;
}
.thumb-bottle img {
  width: 100%;
  height: 100%;
}

.thumb-footer {
  @apply flex
  justify-between
  px-8
  lg:px-4
  py-4
  uppercase
  font-rubik
  text-sm
  lg:text-xs;
}
</style>
