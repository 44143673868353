<template>
  <div class="relative flex justify-center pt-20">
    <div
      class="
        text-center
        flex flex-col
        items-center
        space-y-6
        lg:space-y-10
        w-4/5
        lg:w-1/2
      "
    >
      <h1>
        {{ $t("contact.title") }}
      </h1>
      <p class="italic text-xl text-gray-500 lg:w-4/5">
        {{ $t("contact.body") }}
      </p>
      <nav class="space-x-8">
        <a href="https://instagram.com/cunucwines" target="_blank"
          ><ion-icon
            name="logo-instagram"
            size="large"
            class="
              transition
              duration-300
              ease-in-out
              text-gray-400
              hover:text-primary
            "
          ></ion-icon
        ></a>
        <a href="https://wa.me/5492616667503" target="_blank"
          ><ion-icon
            name="logo-whatsapp"
            size="large"
            class="
              transition
              duration-300
              ease-in-out
              text-gray-400
              hover:text-primary
            "
          ></ion-icon
        ></a>
      </nav>
      <div>
        <video
          src="../assets/video/cunuc.mp4"
          poster="../assets/images/poster.jpg"
          controls
          class="rounded-md"
        >
          Tu navegador no admite el elemento <code>video</code>.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>