import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./styles/tailwind.css";
import router from "./router";
import english from "./locales/english.json";
import spanish from "./locales/spanish.json";

const i18n = createI18n({
  globalInjection: true,
  legacy: true,
  locale: "spanish", // set locale
  fallbackLocale: "english", // set fallback locale
  messages: {
    english,
    spanish,
  },
});

const app = createApp(App);

app.use(router);
app.use(i18n);
app.mount("#app");
