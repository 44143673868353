<template>
  <div
    class="
      grid grid-cols-1
      lg:grid-cols-2
      gap-8
      lg:max-w-screen-lg
      mx-auto
      pb-20
    "
  >
    <div class="space-y-8 px-8">
      <h2>
        {{ $t("family.title") }} <br />
        <span class="font-bold">{{ $t("family.subtitle") }}</span>
      </h2>
      <p class="text-xl text-gray-500 italic">
        {{ $t("family.p_01") }}
      </p>
      <p class="text-xl text-gray-500 italic">
        {{ $t("family.p_02") }}
      </p>
      <p class="text-xl text-gray-500 italic">
        {{ $t("family.p_03") }}
      </p>
    </div>
    <div class="relative">
      <swiper
        :slides-per-view="1"
        :space-between="0"
        @swiper="onSwiper"
        :autoplay="{
          delay: 2500,
        }"
        :effect="'fade'"
        :loop="true"
        class="mb-8"
      >
        <swiper-slide
          ><img src="../assets/images/barricas.jpg" alt="" class="w-full"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/images/family-work.jpg" alt="" class="w-full"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/images/slide-two.jpg" alt="" class="w-full"
        /></swiper-slide>
      </swiper>
      <p class="text-xl text-gray-500 italic px-8 lg:px-0">
        {{ $t("family.p_04") }}
      </p>
      <div class="hidden lg:block absolute z-0 -top-16 -left-20 opacity-50">
        <img src="../assets/mza-arg.svg" alt="" class="w-40" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, EffectFade } from "swiper/core";
SwiperCore.use([Autoplay, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped>
</style>