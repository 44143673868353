<template>
  <footer class="bg-secondary-light">
    <div
      class="
        grid grid-cols-1
        md:grid-cols-3
        gap-8
        w-3/4
        mx-auto
        py-20
        text-left
        font-rubik
        text-sm
      "
    >
      <div>
        <h3>Representante en Mendoza</h3>
        <p class="text-gray-500">
          Rutas del Sol Agricola S.A <br />
          Moyano s/n Ing. Gustavo Andre Lavalle Mendoza, Argentina <br />
          <u>Teléfono</u>: 261-6667503 <br />
          <u>eMail</u>: comercial@cunuc.com.ar
        </p>
      </div>
      <div>
        <h3>Representante en Cordoba</h3>
        <p class="text-gray-500">
          Emprendimiento Industrial Mediterraneo S.A. <br />
          Av. La Voz Del Interior 6926 - CPA X5008HLD, Córdoba Capital,
          Argentina
          <br />
          <u>Tel/Fax</u>: (54-351) 4751775 <br />
          <u>eMail</u>: comercioexterior@motoskeller.com.ar
        </p>
      </div>
      <div>
        <h3>Representante en China</h3>
        <p class="text-gray-500">
          Fuzhou Xinguanrong International Trading CO., LTD - Shop 120, 125, 1st
          Floor, Building 12, Zhonglian Tianyu Pashe I, Yinxi Street Fuqing
          City, Fujian Province, China <br />
          <u>Teléfono</u>: 0086-(591) 85209966 - 18650058523 <br />
          <u>eMail</u>: 236016283@qq.com
        </p>
      </div>
    </div>
    <div
      class="
        py-8
        text-center text-xs text-gray-600
        font-rubik
        uppercase
        tracking-wider
        bg-white
      "
    >
      Cunuc Wines &copy;2021
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>