import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/Layout.vue";
import NotFound from '../views/NotFound.vue';

const routes = [
  { path: '/', component: Layout, meta: { title: 'Layout' } },
  { path: '/:path(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
