<template>
  <div>
    <select
      v-model="$i18n.locale"
      id="location"
      name="location"
      class="
        fixed
        z-50
        top-0
        right-0
        items-center
        justify-center
        pl-2
        pr-8
        py-2
        border border-transparent
        font-rubik
        text-sm text-gray-800
        bg-secondary
        hover:bg-gray-200
        focus:outline-none
      "
    >
      <option>spanish</option>
      <option>english</option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: "es",
    };
  },
  mounted() {
    console.log("object ", this.$i18n);
  },
};
</script>