<template>
  <div>
    <header>
      <Header />
    </header>
    <section id="home">
      <Hero />
    </section>
    <section id="family">
      <Family />
    </section>
    <section id="wines">
      <Wines />
    </section>
    <section id="contact">
      <Contact />
    </section>
    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "../components/Header";
import Hero from "./Hero";
import Family from "./Family";
import Wines from "./Wines";
import Contact from "./Contact";
import Footer from "./Footer";

export default {
  name: "Layout",
  components: {
    Header,
    Hero,
    Family,
    Wines,
    Contact,
    Footer,
  },
};
</script>

<style scoped>
#contact {
  padding: 100px 0;
  background: url("../assets/images/contact-image.jpg") no-repeat;
  background-size: cover;
  background-position-y: 100px;
}

@media (max-width: 600px) {
  #contact {
    background-position-y: 200px;
  }
}
</style>
