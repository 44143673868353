<template>
  <div class="relative min-h-screen flex items-center justify-center">
    <div
      class="
        text-center
        flex flex-col
        items-center
        space-y-6
        lg:space-y-10
        w-4/5
        lg:w-1/2
      "
    >
      <img src="../assets/cunuc.svg" alt="Cunuc" class="w-44 lg:w-64" />
      <h1>
        {{ $t("hero.title") }}
      </h1>
      <p class="italic text-xl text-gray-500 lg:w-4/5">
        {{ $t("hero.body") }}
      </p>
      <router-link to="/#family">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="animate-bounce h-6 w-6 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 17l-4 4m0 0l-4-4m4 4V3"
          />
        </svg>
      </router-link>
    </div>
  </div>
</template>